import React from 'react'
import { graphql } from 'gatsby'
import { kebabCase } from 'lodash'

import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import PostItem from "../components/postItem"
import { Link } from "gatsby"
import styled from 'styled-components'

import EmailListForm from "../components/emailListForm"
import BlogHeader from "../components/blogWrapper"

import Badge from '@material-ui/core/Badge';


import Signup from "../components/signup";

const PBox = styled(AnimatedBox)`
max-width: 1600px;
margin: 0 auto;
`

const PostFeed = styled(AnimatedBox)` 
position: relative;
display: flex;
flex-wrap: wrap;
padding: 20px 0 0 0;
`

const TagsUl = styled.ul`
  display: flex;
  justify-content: center; // Centers the tags horizontally
  align-items: center; // Centers the tags vertically
  overflow-x: auto;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  list-style-type: none;
  text-align: center;
`;

const Tags = styled.li`
  display: flex;
  align-items: center; // Centers the content vertically
  padding: 0 13px;
  text-transform: uppercase;
`;

const TagIndicator = styled(Badge)`
  padding: 0 8px 2px;
  .MuiBadge-colorSecondary{
    background-color: black;
  }
`


const TagLink = styled(Link)`
  text-decoration: none;
  letter-spacing: 0.1em;
  font-size: 85%;
  padding: 5px 10px; // You can adjust the padding as needed
  border-radius: 10px; // This will round the corners of the border

  &.active {
    font-weight: bold;
    border: 2px solid black; // Replace with your desired border color
    background-color: black; // Replace with your desired background color
    color: #ffffff;
  }
`;

// this is only active when the location pathname is exactly
// the same as the href.
const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: "active" } : {}
}

const ExactNavLink = props => (
  <Link getProps={isActive} {...props} />
)

const Blog = ( { data: { allNotion, tagsList : { group } } } )=> {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })


  return (
    <Layout color="black">
      <SEO title="Conrad's Sharing Space" desc="This is my sharing space where I write about innovative new ways to solve everyday challenges ✍🏻" />
        <PBox>
        <BlogHeader></BlogHeader>
        <AnimatedBox px={[6, 6, 6, 6, 8, 12]}>
          <TagsUl>
            <Tags>
              <TagLink activeClassName='active' to={'/blog/'}>All</TagLink>
              
            </Tags>
            {group.map(tag => (
              <Tags key={tag.fieldValue}>
                <TagLink activeClassName='active' to={`/blog/tags/${kebabCase(tag.fieldValue)}/`}>
                  {tag.fieldValue}
                  <TagIndicator badgeContent={tag.totalCount}>
                </TagIndicator>
                </TagLink>
              </Tags>
            ))}
          </TagsUl>
        </AnimatedBox>
        
        <AnimatedBox px={[0, 0, 0, 6, 8, 12]}>
        <div className="homePosts">
              <PostFeed style={pageAnimation} px={[6, 6, 6, 6, 8, 12]}>
        {
            allNotion.edges.map(e => <PostItem data={e} />)
        }
      </PostFeed>
      </div>
      </AnimatedBox>
      <AnimatedBox id="mailingList" style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>

        
      <div style={{ textAlign: 'center' }}>
      <Signup cta="learningintopractice" />
      </div>

      {/*<EmailListForm></EmailListForm>*/}
      </AnimatedBox>
      </PBox>
    </Layout>
  )
  
}

export default Blog
export const query = graphql`
  query {
    allNotion(
      filter: {properties: {status: {value: {name: {eq: "published"}}}}}
      sort: {fields: childMdx___frontmatter___publish_date___start, order: DESC}
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 95, maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          childMdx {
            frontmatter {
              title
              tags {
                name
              }
              content_type {
                name
              }
              desc
              status {
                name
              }
              url
              read_time
              publish_date {
                start(fromNow: false, formatString: "YYYY-MMM-DD")
              }
            }
            timeToRead
          }
        }
      }
}
    tagsList: allNotion(
        filter: {properties: {status: {value: {name: {eq: "published"}}}}}
      ) {
        group(field: properties___tags___value___name) {
          fieldValue
          totalCount
        }
      }
  }
`